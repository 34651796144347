<template>

  <b-card-code
    title="Profile"
  >
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{ handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storeStudentProfile)">
    <b-alert
      variant="danger"
      show
      dismissible
      v-if="validationErrors && validationErrors.message"
    >
      <div class="alert-body">
        <span><strong>{{validationErrors.message}}</strong></span>
      </div>
    </b-alert>
    <b-media tag="li">
      <template #aside>
        <b-avatar
          :src="preview"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h5 class="mt-0 mb-1">Profile Image</h5>
      <ValidationProvider rules="required" name="Profile Image" v-slot="{ errors }">
      <b-form-file
          ref="refInputEl"
          v-model="studentProfile.user_profile_image"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-card-text>Allowed JPG, JPEG or PNG. Max size of 2MB</b-card-text>
        <small class="text-danger">{{ errors[0] }}</small>
      </ValidationProvider>
    </b-media>
    
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Nombre"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Nombre" v-slot="{ errors }">
              <b-form-input
                v-model = "studentProfile.name"
                id="mc-first-name"
                placeholder="Nombre"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Apellidos"
            label-for="mc-last-name"
          >
          <ValidationProvider rules="required" name="Apellidos" v-slot="{ errors }">
            <b-form-input
              v-model = "studentProfile.surname"
              id="mc-last-name"
              placeholder="Apellidos"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Telefono"
            label-for="mc-city"
          >
            <ValidationProvider rules="required" name="Telefono" v-slot="{ errors }">
              <b-form-input
                v-model = "studentProfile.telephone_num"
                id="mc-city"
                placeholder="Telefono"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Email"
          >
            <div class="form-label-group">
              <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
                <b-form-input
                  v-model = "studentProfile.email"
                  id="mc-email"
                  type="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger" v-if="validationErrors && validationErrors.validationErrors.email.length">
                  {{ validationErrors.validationErrors.email[0] }}
                </small>
              </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Fecha de nacimiento"
            label-for="mc-company"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required|minAge" name="Fecha de nacimiento" v-slot="{ errors }">
              <b-form-datepicker
                id="fecha-de-nacimiento"
                v-model="studentProfile.dob"
                :show-decade-nav = "true"
                class="mb-1"
                placeholder="Fecha de nacimiento"
                locale='fr'
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
              
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Direccion Completa"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Direccion Completa" v-slot="{ errors }">
              <b-form-input
                id="mc-email"
                v-model="studentProfile.address"
                placeholder="Direccion Completa"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Codigo Postal"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Codigo Postal" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.zipcode"
                id="mc-email"
                placeholder="Codigo Postal"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Ciudad"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Ciudad" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.city"
                id="mc-email"
                placeholder="Ciudad"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="DNI/NIE o Pasaporte"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="DNI/NIE o Pasaporte" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.id_num"
                id="mc-email"
                placeholder="DNI/NIE o Pasaporte"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        <!--
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Fecha de expedicion"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="Fecha de expedicion" v-slot="{ errors }">
              <b-form-input
                v-model="studentProfile.id_expedition_num"
                id="mc-email"
                placeholder="Fecha de expedicion"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        -->
        <b-col md="6">
          <b-form-group
            label="fecha de expedicion"
            label-for="mc-company"
          >
            <div class="form-label-group">
            <ValidationProvider rules="required" name="fecha de expedicion" v-slot="{ errors }">
              <b-form-datepicker
                id="fecha-de-expedicion"
                v-model="studentProfile.expedition_date"
                class="mb-1"
                placeholder="Fecha de expedicion"
                locale='es'
                :max="max"
                :min="min"
                show-decade-nav
                start-weekday='1'
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
              
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
        <b-form-group
            label-for="mc-email"
            label="Curso"
        >
        <ValidationProvider rules="required" name="Curso" v-slot="{ errors }">
          <v-select
            v-model="studentProfile.course"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="parentCourses"
            :reduce="course => course"
            placeholder = 'Select Course'
            @input="setSelected"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
        </b-form-group>
      </b-col>
      
        <b-col md="6" v-if="subCoursesData.length > 0">
            <b-form-group
                label-for="mc-email"
                label="Sub Curso"
            >
                <ValidationProvider rules="required" name="Sub Curso" v-slot="{ errors }">
                    <v-select
                        v-model="studentProfile.subCourse"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="subCoursesData"
                        :reduce="subCourse => subCourse"
                        placeholder = 'Select Sub Curso'
                        @input="setSubCourse"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
            </b-form-group>
        </b-col>
        <b-col md="6" v-if="studentProfile.course_name != 'Club' && studentProfile.course_name != ''">
          <b-form-group
            label=""
            v-slot="{ ariaDescribedby }">
            <label></label>
            <b-form-checkbox class="mb-2" v-model="studentProfile.sailing_club" name="check-button" switch>
              Clica aquí si también formas parte del Club de navegación
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        
      </b-row>
      <b-row class="mt-2">
        <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1" 
          >
            Submit
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver> 
  </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BCol, BAlert, BAvatar, BOverlay, BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ProfileService from '@/services/profile.service.js';
import CourseService from '@/services/course.service.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BAvatar,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      studentProfile: {
        name: '',
        surname: '',
        email: '',
        telephone_num: '',
        dob: '',
        address: '',
        zip_code: '',
        city: '',
        id_num: '',
        course_name: '',
        id_expedition_num: '',
        expedition_date: '',
        course_id: '',
        attachment_url: '',
        user_profile_image: null,
        sub_course_id: '',
        subCourse: '',
        course: '',
        sailing_club: false
      },
      preview: '',
      courses: [],
      subCourses: [],
      parentCourses: [],
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/avatars/user-avatar.png',
      showOverlay: false,
      errors: '',
      max: moment().format('YYYY-MM-DD'),
      min: moment().subtract(10, 'years').format('YYYY-MM-DD')
    }
  },
  computed: {
    validationErrors(){
      return this.errors.data
    },
    subCoursesData() {
      return this.subCourses
    }
  },
  methods: {
    setSelected(value)
    {
        this.studentProfile.sub_course_id = ''
        this.studentProfile.subCourse = ''
        this.studentProfile.course_id = value.id
        this.studentProfile.course_name = value.name 
        
        this.subCourses = this.courses.filter(course => {
            if(course.parent_id == value.id) {
                return course;
            }
        })
    },
    setSubCourse(value)
    {
        this.studentProfile.sub_course_id = value.id
        this.studentProfile.course_name = value.name 
    },
    
    dateDisabled(ymd, date) {
      
      let currentDate = (moment(new Date()).format('YYYY')) - 10 
      let getYear = date.getFullYear();
      return currentDate > getYear;
    },
    inputImageRenderer(event) {
      
      var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
      reader.readAsDataURL(this.studentProfile.user_profile_image);
    },
    storeStudentProfile() {
        let getData = this.storeFormData();
        this.showOverlay = true;
        this.$store.dispatch('storeStudentProfile', getData).then(() => {
					this.showOverlay = false;
          window.location.href = this.$store.getters.getRedirectionUrl			
				}).catch((error) => {
          this.showOverlay = false;
          this.errors = error.response
				})  
    },
    storeFormData()
    {
      let formData = new FormData();
      for(let studentKey in this.studentProfile) {
          formData.append(studentKey, this.studentProfile[studentKey]);
      }
      return formData;
    },
    getCourses()
    {
      CourseService.getAll().then(response => {
        this.courses = response.data.data.courses;
        this.parentCourses = this.courses.filter(course => {
            if(course.parent_id == null) {
                return course;
            }
        })
        }).catch(error => {
            console.log(error)
        });
    },
    
  },
  created() {
      if(this.$store.getters.getUser) {
        this.studentProfile.email = this.$store.getters.getUser.email;
      }
      this.getCourses();
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
